import * as React from 'react';
import {IInstrumentBlockProps, IInstrumentBlockConfig, IColumnsData} from './instrument-block.props.autogenerated';
import * as Msdyn365 from "@msdyn365-commerce/core";
import classnames from "classnames";
import {getTelemetryObject, IModuleProps, INodeProps, ITelemetryContent} from "@msdyn365-commerce-modules/utilities";
import LinksComponent from './components/links';

/* Interfaces */
export interface IInstrumentBlockViewProps extends IInstrumentBlockProps<{}>{
	mainHeading?: string;
	mainImage?: Msdyn365.IImageData;
	columns?:JSX.Element,
	instrumentBlockContainer: IModuleProps;
	imageContainer: INodeProps;
}
export interface IInstrumentBlockFullConfig extends IInstrumentBlockConfig {
	// eslint-disable-next-line @typescript-eslint/naming-convention -- This field comes from SDK.
	msdyn365__moduleLayout?: string;
}

export class InstrumentBlock extends React.PureComponent<IInstrumentBlockProps<{}>> {
	private readonly telemetryContent: ITelemetryContent = getTelemetryObject(
		this.props.context.request.telemetryPageName!,
		this.props.friendlyName,
		this.props.telemetry
	);

	private _buildColumns(columns: IColumnsData[]|undefined, mainHeading: string|undefined):JSX.Element{
		const _headingTag = mainHeading ? `h3` : `h2`;

		return (
			<div className="row">
				{columns?.map((item, idx) => {
					return (
						<div className="col-md inst-block__column" key={idx}>
							<Msdyn365.Text className="inst-block__title" tag={_headingTag} text={item.heading}/>
							<Msdyn365.RichTextComponent
								text={item.paragraph}
								className='inst-block__text'
							/>
							<LinksComponent
								{...{
									links: item.links,
									requestContext: this.props.context.request,
									telemetryContent: this.telemetryContent
								}}
							/>
						</div>
					)
				})}
			</div>
		)
	}

    public render(): JSX.Element | null {
		const {
			mainHeading,
			mainImage,
			columns,
			className
			/*clientRender*/
		} = this.props.config;

		//Don't render the module without a main image
		if (!mainImage){
			this.props.context.telemetry.error('Content block content is empty, module wont render.');
			return null;
		}

		const imageProps = {
			gridSettings: this.props.context.request.gridSettings ?? {},
			imageSettings: mainImage?.imageSettings
		};
		const instrumentBlockImage = (
			<Msdyn365.Image
				{...mainImage}
				{...imageProps}
				requestContext={this.props.context.request}
				preserveImageSpace={true}
				editProps={{
					key: this.props.config.mainImage ?? {},
					requestContext: this.props.context.request,
					moduleType: this.props.typeName,
					imagePropertyName: 'mainImage',
					moduleId: this.props.id,
					layout: (this.props.config as IInstrumentBlockFullConfig).msdyn365__moduleLayout
				}}
				shouldSkipToMainImage
			/>
		);

		return this.props.renderView({
			...this.props,
			mainHeading: mainHeading && (<h2 className='inst-block__mainTitle'>{mainHeading}</h2>),
			mainImage: instrumentBlockImage,
			instrumentBlockContainer: {
				moduleProps: this.props,
				className: classnames('inst-block', className)
			},
			imageContainer: { className: 'inst-block__image' },
			columns: this._buildColumns(columns, mainHeading)
		}) as React.ReactElement;
    }
}
export default InstrumentBlock;
