import * as Msdyn365 from '@msdyn365-commerce/core';
import { getPayloadObject, getTelemetryAttributes, ITelemetryContent, onTelemetryClick } from '@msdyn365-commerce-modules/utilities';
import * as React from 'react';
import { ILinksData } from '../instrument-block.props.autogenerated';

export interface IInstrumentLinks {
    links?: ILinksData[];
    requestContext: Msdyn365.IRequestContext;
    telemetryContent: ITelemetryContent;
}

export class InstrumentLinks extends React.PureComponent<IInstrumentLinks>{
    public render(): JSX.Element {
        const editableLinks = this._mapEditableLinks(this.props.links);
        return (
            <div className='inst-block__cta'>
                {editableLinks && editableLinks.length > 0 ? (
                    <Msdyn365.Links
                        links={editableLinks}
                        editProps={{ requestContext: this.props.requestContext }}
                    />
                ) : null}
            </div>
        );
    }

    private _mapEditableLinks = (link_data: ILinksData[]|undefined): Msdyn365.ILinksData[] | null => {
        if (!link_data || link_data.length === 0) {
            return null;
        }
        const editableLinks: Msdyn365.ILinksData[] = [];
        link_data.forEach((link, index) => {
            // Construct telemetry attribute to render
            const payLoad = getPayloadObject('click', this.props.telemetryContent, '', '');
            const linkText = link.linkText ? link.linkText : '';
            payLoad.contentAction.etext = linkText;
            const attributes = getTelemetryAttributes(this.props.telemetryContent, payLoad);
            const btnClass = index === 0 ? 'msc-cta__primary' : 'msc-cta__secondary';
            const editableLink: Msdyn365.ILinksData = {
                ariaLabel: link.ariaLabel,
                className: btnClass,
                linkText: link.linkText,
                linkUrl: link.linkUrl.destinationUrl,
                openInNewTab: link.openInNewTab,
                role: "link",
                additionalProperties: attributes,
                onClick: onTelemetryClick(this.props.telemetryContent, payLoad, linkText)
            };
            editableLinks.push(editableLink);
        });

        return editableLinks;
    }
}
export default InstrumentLinks;