import * as React from 'react';
import { Module, Node } from '@msdyn365-commerce-modules/utilities';
import { IInstrumentBlockViewProps } from './instrument-block';

const ContentBlockView: React.FC<IInstrumentBlockViewProps> = props => {
    const {
		mainHeading,
		mainImage,
		columns,
		instrumentBlockContainer,
		imageContainer
    } = props;

    return (
		<Module {...instrumentBlockContainer}>
			{mainHeading}
			<Node {...imageContainer}>{mainImage}</Node>
			{columns}
		</Module>
	);
};
export default ContentBlockView;